import React, {createContext, useState, useEffect, useCallback} from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ isLoggedIn: false, memberName: null, memberId: null });
    const [loading, setLoading] = useState(true);

    const checkAuthStatus = useCallback(() => {
        setLoading(true);
        const jwtToken = document.cookie.split('; ').find(row => row.startsWith('Authorization='));
        if (jwtToken) {
            const token = jwtToken.split('=')[1];
            try {
                const payload = jwtDecode(token);
                setAuth({ isLoggedIn: true, memberName: payload.name, memberId: payload.memberId });
            } catch (error) {
                console.error('JWT 디코딩 중 오류 발생:', error);
                setAuth({ isLoggedIn: false, memberName: null, memberId: null });
            }
        } else {
            setAuth({ isLoggedIn: false, memberName: null, memberId: null });
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        checkAuthStatus();
    }, [checkAuthStatus]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, checkAuthStatus, loading }}>
            {children}
        </AuthContext.Provider>
    );
};