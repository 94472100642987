import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useUserContext} from "../../UserContext";
import styles from './CreatePost.module.css';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const {user, loading} = useUserContext();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (!user) {
        alert('로그인이 필요합니다.');
        navigate('/community');
      } else {
        setShowForm(true);
      }
    }
  }, [user, loading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.go-reverse.site/communities', {title, content});
      navigate('/community');
    } catch (error) {
      console.error('게시글 작성 실패', error);
      alert('게시글 작성에 실패했습니다.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!showForm) {
    return null;
  }

  return (
      <div className={styles.createPost}>
        <h2>게시글 작성</h2>
        <form onSubmit={handleSubmit}>
          <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="제목"
              required
          />
          <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="내용"
              required
          />
          <div className={styles.buttons}>
            <button type="submit">확인</button>
            <button type="button" onClick={() => navigate('/community')}>취소
            </button>
          </div>
        </form>
      </div>
  );
};

export default CreatePost;