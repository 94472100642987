import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";

function AiChat() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <NavBar
                activeValue = "ai-chat"
            />
            <main style={{ flex: 1 }}>
                {/* <h1>AI 상담 페이지입니다.</h1> */}
            </main>
            <Footer/>
        </div>
    );
}

export default AiChat;