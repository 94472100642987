import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = ({isAdmin}) => {
  const currentYear = new Date().getFullYear();

  return (
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.content}>
            <p className={styles.copyright}>
              © {currentYear} Reverse. All rights reserved.
            </p>
            <nav className={styles.nav}>
              <Link to="/terms" className={styles.link}>이용약관</Link>
              <Link to="/privacy" className={styles.link}>개인정보처리방침</Link>
              <Link to="/contact" className={styles.link}>문의하기</Link>
              {isAdmin && (
                  <Link to="/admin" className={styles.adminLink}>관리자 페이지
                    이동</Link>
              )}
            </nav>
          </div>
        </div>
      </footer>
  );
};

export default Footer;