import React from 'react';
import styles from './PreparationModal.module.css';

const PreparationModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <h2 style={{ marginBottom: '16px' }}>서비스 준비중입니다.</h2>
            <p>회생에 필요한 문서를 AI를 통해 손쉽게 작성할 수 있도록 서비스를 준비중입니다. 빠른 시일내에 준비하여 찾아뵙겠습니다.</p>
                <button onClick={onClose} className={styles.closeButton}>닫기</button>
            </div>
        </div>
    );
    
};

export default PreparationModal;
