import React from "react";

function DiagnosisScoreCheck({diagnosis_result}) {

    if(diagnosis_result >= 90) {
        return (
            <div className=" col-md-12">
                <div className="latest">
                    <div className="nostrud">
                        <div className="result-head-div">
                            <div>
                                <text className="result-id-text">홍길동</text>
                                <text className="result-title"> 님의</text>
                            </div>
                            <div>
                                <text className="result-title">회생 점수는 <text className="result-score">{diagnosis_result}점</text>이에요!</text>
                            </div>
                        </div>
                        <div className="emoji-div">
                            <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Face.png" alt="Grinning Face" width="220" height="220" />
                        </div>
                        <div className="result-foot-div">
                            <text className="result-best-text">" 회생 신청이 가능해요. "</text>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if(diagnosis_result >= 60){
        return (
            <div className=" col-md-12">
                <div className="latest">
                    <div className="nostrud">
                        <div className="result-head-div">
                            <div>
                                <text className="result-id-text">홍길동</text>
                                <text className="result-title"> 님의</text>
                            </div>
                            <div>
                                <text className="result-title">회생 점수는 <text className="result-score">{diagnosis_result}점</text>이에요!</text>
                            </div>
                        </div>
                        <div className="emoji-div">
                            <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face.png" alt="Smiling Face" width="220" height="220" />
                        </div>
                        <div className="result-foot-div">
                            <text className="result-good-text">" 회생 신청이 가능할 수도 있어요. "</text>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
            return (
                <div className=" col-md-12">
                    <div className="latest">
                        <div className="nostrud">
                            <div className="result-head-div">
                                <div>
                                    <text className="result-id-text">홍길동</text>
                                    <text className="result-title"> 님의</text>
                                </div>
                                <div>
                                    <text className="result-title">회생 점수는 <text className="result-score">{diagnosis_result}점</text>이에요!</text>
                                </div>
                            </div>
                            <div className="emoji-div">
                                <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pleading%20Face.png" alt="Pleading Face" width="220" height="220" />
                            </div>
                            <div className="result-foot-div">
                                <text className="result-bad-text">" 회생 신청이 어려울 것 같아요. "</text>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

}
export default DiagnosisScoreCheck;