import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SignupForm from "../components/SignupForm";
import styles from "../components/Signup.module.css";

function Signup() {
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async ({ name, phone, lawyerNumber }) => {
        // 입력값 검증
        if (name.length < 2) {
            setError("이름은 2글자 이상 입력해야 합니다.");
            return;
        }

        const phonePattern = /^010-\d{4}-\d{4}$/;
        if (!phonePattern.test(phone)) {
            setError("전화번호 형식이 올바르지 않습니다. (예: 010-XXXX-XXXX)");
            return;
        }

        setError(""); // 에러 초기화

        // Axios를 사용하여 POST 요청
        axios.post("https://api.go-reverse.site/api/v1/members", {
            name,
            phone,
            lawyerNumber,
        }, { withCredentials: true })
            .then((response) => {
                alert("회원가입되었습니다.");
                // 쿠키 재발급

                const token = response.data.token; // 서버에서 받은 JWT 토큰
                document.cookie = `Authorization=${token}; path=/; max-age=3600; Secure; SameSite=None; HttpOnly`;

                window.location.href = "https://api.go-reverse.site/oauth2/authorization/kakao";
            })
            .catch((error) => {
                console.error("회원가입 실패:", error.response ? error.response.data : error);
                setError("회원가입에 실패했습니다. 다시 시도해주세요.");
            });
    };

    return (
        <div className={styles.container}>
            <Header />
            <main className={styles.main}>
                <h1>회원가입</h1>
                <SignupForm onSubmit={handleSubmit} error={error} />
            </main>
            <Footer />
        </div>
    );
}

export default Signup;
