import React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import CommunityMain from '../components/Post/CommunityMain';
import CreatePost from '../components/Post/CreatePost';
import PostDetail from '../components/Post/PostDetail';
import EditPost from '../components/Post/EditPost';
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";

const Community = () => {
  const navigate = useNavigate();

  return (
      <div>
        <NavBar
            activeValue="community"
        />
        <Routes>
          <Route index element={<CommunityMain/>}/>
          <Route path="create" element={<CreatePost/>}/>
          <Route path=":postId" element={<PostDetail/>}/>
          <Route path="edit/:postId" element={<EditPost/>}/>
        </Routes>
        <Footer/>
      </div>
  );
}

export default Community;