import React, {useEffect,useState} from "react";
import NavBar from "../components/Navbar";
import DiagnosisScoreCheck from "../components/DiagnosisScoreCheck";
import {useNavigate} from "react-router-dom";
import FooterBlack from "../components/FooterBlack";

function DiagnosisResult() {
    const [diagnosis_result, setDiagnosis_result] = useState("0")

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const toLawFirm = () => {
        <html>
        navigate('#')
        </html>

    }

    async function  getResult() {
        const response = await fetch("https://api.go-reverse.site/api/v1/diagnosis-result-send")
        const result = await response.text()
        // console.log(result)
        setDiagnosis_result(result)
    }

    getResult()


    return (
        <body className="main-layout inner_page">
        <NavBar
            activeValue = "diagnosis"
        />
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage text_align_center ">
                            <div className="" >
                                <div >
                                    <div className="services_box text_align_center">
                                        <DiagnosisScoreCheck diagnosis_result={diagnosis_result}/>
                                        <div className="next-button">
                                            <button className="read_more"
                                                    onClick={() => {
                                                        toLawFirm();
                                                    }}
                                            >법무법인 알아보기</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <FooterBlack/>
        </body>

    );
}
export default DiagnosisResult;