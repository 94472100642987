import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './InfoSlider.module.css';

const InfoSlider = ({ cards }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <div className={styles.sliderContainer}>
            <Slider {...settings}>
                {Array.isArray(cards) && cards.length > 0 ? (
                    cards.map((card, index) => (
                        <div key={index} className={styles.slide}>
                            <img src={card.imageUrl} alt={card.title} className={styles.cardImage} />
                            <div className={styles.cardContent}>
                                <h3 className={styles.cardTitle}>{card.title}</h3>
                                <p className={styles.cardDescription}>{card.content}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.noContent}>No content available</div>
                )}
            </Slider>
        </div>
    );
};

export default InfoSlider;