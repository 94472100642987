import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import InfoSlider from "../components/Info/InfoSlider";
import InfoSearch from "../components/Info/InfoSearch";
import styles from './Info.module.css';

const Info = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.go-reverse.site/api/v1/useful-info');
        setCards(response.data);
      } catch (error) {
        console.error('유용한 정보 데이터 FETCH 에러:', error);
      }
    };
    fetchData();
  }, []);

  return (
      <div className={styles.pageContainer}>
        <NavBar
            activeValue="info"
        />
        <main className={styles.mainContent}>
          <div className={styles.contentWrapper}>
            <div
                className={`${styles.componentContainer} ${styles.sliderContainer}`}>
              {cards.length > 0 && <InfoSlider cards={cards}/>}
            </div>
            <div
                className={`${styles.componentContainer} ${styles.searchContainer}`}>
              <InfoSearch/>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
  );
}

export default Info;