import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styles from './LawyerApproval.module.css';

const LawyerApproval = () => {
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchLawyerRequests();
  }, [page, size]);

  const fetchLawyerRequests = async () => {
    try {
      const response = await axios.get('https://api.go-reverse.site/api/v1/members/paged', {
        params: {page, size}
      });
      const lawyerRequests = response.data.content.filter(member =>
          member.role === 'ROLE_MEMBER' && member.lawyerNumber
      );
      setMembers(lawyerRequests);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('법조인 권한 승인 목록 불러오기 중 에러 발생:', error);
    }
  };

  const handleApprove = async (id) => {
    try {
      await axios.put(`https://api.go-reverse.site/api/v1/members/${id}/approve-lawyer`);
      fetchLawyerRequests();
    } catch (error) {
      console.error('법조인 권한 승인 에러 :', error);
    }
  };

  return (
      <div className={styles.lawyerApproval}>
        <h2>변호사 승인 요청</h2>
        <table>
          <thead>
          <tr>
            <th>이름</th>
            <th>이메일</th>
            <th>전화번호</th>
            <th>변호사 번호</th>
            <th>승인</th>
          </tr>
          </thead>
          <tbody>
          {members.map((member) => (
              <tr key={member.memberId}>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.phone}</td>
                <td>{member.lawyerNumber}</td>
                <td>
                  <button onClick={() => handleApprove(member.memberId)}>승인
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
        <div className={styles.pagination}>
          <button onClick={() => setPage(page - 1)} disabled={page === 0}>
            이전
          </button>
          <span>{page + 1} / {totalPages}</span>
          <button onClick={() => setPage(page + 1)}
                  disabled={page === totalPages - 1}>
            다음
          </button>
        </div>
      </div>
  );
};

export default LawyerApproval;