import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('https://api.go-reverse.site/api/v1/user');
        setUser(response.data);
        console.log('User State:', response.data);
      } catch (error) {
        console.error('사용자 정보 불러오기 실패', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  return (
      <UserContext.Provider value={{user, setUser, loading}}>
        {children}
      </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);