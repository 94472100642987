import React from "react";
import {useNavigate} from "react-router-dom"
import NavBar from "../components/Navbar";
import FooterBlack from "../components/FooterBlack";

function PreDiagnosis() {

    const navigate = useNavigate();

    const toDiagnosis = () => {
        navigate('/diagnosis')
    }
    return (
        <body className="main-layout inner_page">
        <NavBar
            activeValue = "diagnosis"
        />
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titlepage text_align_center ">
                            <div className="" >
                                <div >
                                    <div className="services_box text_align_center">
                                        <div className=" col-md-12">
                                            <div className="latest">
                                                <div className="nostrud">
                                                    <h3>아래 조건을 모두 만족하면 다음을 눌러주세요.</h3>
                                                    <div className="check-list">
                                                        <ul>
                                                            <li>● 현재 일정한 수입이 있나요?</li>
                                                            <li>● 현재 보유 재산 보다 채무가 많은가요?</li>
                                                            <li>● 채무 금액이 1000만원 이상인가요?</li>
                                                            <li>● 무담보 채무가 10억 이하 혹은 담보부 채무가 15억 이하인가요?</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="next-button">
                                            <button className="read_more"
                                                onClick={() => {
                                                    toDiagnosis();
                                                }}
                                            >다음</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <FooterBlack/>
        </body>

);
}

export default PreDiagnosis;