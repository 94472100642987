import React, { useEffect } from 'react';
import styles from './LoginModal.module.css';
import kakaoLoginImage from '../assets/kakao_login_image.png';

const LoginModal = ({ isOpen, onClose }) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]); // isOpen을 의존성 배열에 추가

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>X</button>
                <h2>로그인</h2>
                <div id='login-buttons' className={styles.loginButtons}>
                    <a href="https://api.go-reverse.site/oauth2/authorization/kakao"
                    className={styles.kakaoLoginButton}>
                        <img src={kakaoLoginImage} alt="카카오로 로그인하기" className={styles.kakaoLoginButton}/>
                    </a>
                </div>

            </div>
        </div>
    );
};

export default LoginModal;
