import React, {useState, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import MemberList from '../components/Admin/MemberList';
import LawyerApproval from '../components/Admin/LawyerApproval';
import styles from './Admin.module.css';

const Admin = () => {
    const [activeTab, setActiveTab] = useState('users');
    // const { isAdmin } = useContext(AuthContext); //권한 확인

    // if (!isAdmin) { //관리자가 아닐경우
    //     return <Navigate to="/" replace />;
    // }

    return (
        <div className={styles.adminPage}>
            <h1 className={styles.title}>관리자 페이지</h1>
            <nav className={styles.nav}>
                <button
                    className={`${styles.navButton} ${activeTab === 'users' ? styles.active : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    회원 목록
                </button>
                <button
                    className={`${styles.navButton} ${activeTab === 'lawyers' ? styles.active : ''}`}
                    onClick={() => setActiveTab('lawyers')}
                >
                    법조인 권한 승인
                </button>
            </nav>
            <div className={styles.content}>
                {activeTab === 'users' && <MemberList/>}
                {activeTab === 'lawyers' && <LawyerApproval/>}
            </div>
        </div>
    );
};

export default Admin;