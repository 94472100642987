import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './css/AiChatIcon.css';

export default function AiChatIcon() {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isTyping, setIsTyping] = useState(false);

    const handleIconClick = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const handleSendMessage = async () => {
        if (message.trim()) {
            const userMessage = { role: 'user', content: message };
            setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
            setMessage('');
            setIsTyping(true);

            try {
                const response = await fetch('http://43.200.37.191:8000/api/v1/ai-chat-stream/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        message: message,
                    }),
                });

                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');

                let assistantMessage = { role: 'assistant', content: '' };
                setChatHistory((prevChatHistory) => [...prevChatHistory, assistantMessage]);

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    setIsTyping(false);

                    const chunk = decoder.decode(value);
                    assistantMessage.content += chunk;

                    setChatHistory((prevChatHistory) => {
                        const newHistory = [...prevChatHistory];
                        newHistory[newHistory.length - 1] = { ...assistantMessage };
                        return newHistory;
                    });
                }
            } catch (error) {
                console.error('Error fetching AI response:', error);
                setIsTyping(false);
            }

            setMessage('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="ai-chat-icon">
                <img
                    src="/imgs/icons/ic_ai_chat.png"
                    alt="AI Chat Icon"
                    onClick={handleIconClick}
                />
            </div>
            <div className={`ai-chat-container ${isChatOpen ? 'open' : ''}`}>
                <div className="ai-chat-header">
                    <strong>리버스 AI 도우미 🧑🏻‍💻</strong>
                    <span className="ai-chat-subtext">회생과 파산에 대해 궁금한 것을 물어보세요</span>
                    <button
                        className="ai-chat-close-button"
                        onClick={handleCloseChat}
                    >
                        X
                    </button>
                </div>
                <div className="ai-chat-body">
                    {chatHistory.map((chat, index) => (
                        <div 
                            key={index} 
                            className={`ai-chat-message ${chat.role === 'user' ? 'ai-chat-message-user' : 'ai-chat-message-assistant'}`}
                        >
                            {chat.role === 'assistant' && (
                                <strong>리버스 AI</strong>
                            )}
                            <div className={`ai-chat-message-content ${chat.role === 'user' ? 'user' : ''}`}>
                                <ReactMarkdown>{chat.content}</ReactMarkdown>
                            </div>
                        </div>
                    ))}
                    {isTyping && (
                        <div className="typing-indicator">
                            <div className="dot">•</div>
                            <div className="dot">•</div>
                            <div className="dot">•</div>
                        </div>
                    )}
                </div>
                <div className="ai-chat-footer">
                    <input
                        type="text"
                        placeholder="메시지를 입력하세요..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="ai-chat-input"
                    />
                    <button
                        onClick={handleSendMessage}
                        className="ai-chat-send-button"
                    >
                        보내기
                    </button>
                </div>
            </div>
        </>
    );
}