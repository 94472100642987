import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styles from './MemberList.module.css';

const MemberList = () => {
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchMembers();
  }, [page, size]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get('https://api.go-reverse.site/api/v1/members/paged', {
        params: {page, size}
      });
      setMembers(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('회원 불러오기 에러:', error);
    }
  };

  return (
      <div className={styles.memberList}>
        <h2>회원 목록</h2>
        <table>
          <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>이메일</th>
            <th>전화번호</th>
            <th>역할</th>
            <th>가입일</th>
          </tr>
          </thead>
          <tbody>
          {members.map((member) => (
              <tr key={member.memberId}>
                <td>{member.memberId}</td>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.phone}</td>
                <td>{member.role}</td>
                <td>{new Date(member.createdAt).toLocaleDateString()}</td>
              </tr>
          ))}
          </tbody>
        </table>
        <div className={styles.pagination}>
          <button onClick={() => setPage(page - 1)} disabled={page === 0}>
            이전
          </button>
          <span>{page + 1} / {totalPages}</span>
          <button onClick={() => setPage(page + 1)}
                  disabled={page === totalPages - 1}>
            다음
          </button>
        </div>
      </div>
  );
};

export default MemberList;