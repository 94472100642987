import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {AuthContext} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import styles from "../components/css/Mypage.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faIdCard, faPhone} from '@fortawesome/free-solid-svg-icons';

const Mypage = () => {
    const navigate = useNavigate()
    const {auth, loading, checkAuthStatus, setAuth} = useContext(AuthContext);
    const [memberData, setMemberData] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchMemberData = async () => {
            if (loading) return; // auth 로딩 중이면 기다림

            if (!auth.isLoggedIn || !auth.memberId) {
                alert("로그인 상태가 아닙니다."); // 경고창 표시
                navigate("/");
                return;
            }

            try {
                const response = await axios.get(`https://api.go-reverse.site/api/v1/members/${auth.memberId}`, {
                    withCredentials: true, // 쿠키를 포함하여 요청
                });
                setMemberData(response.data);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    // 토큰이 만료되었거나 유효하지 않은 경우
                    checkAuthStatus(); // auth 상태 재확인
                } else {
                    setError(err.response ? err.response.data.message : "데이터를 가져오는 데 실패했습니다.");
                }
            } finally {
                setPageLoading(false);
            }
        };

        fetchMemberData();
    }, [auth.isLoggedIn, auth.memberId, loading, checkAuthStatus, navigate]);

    const deleteMember = async () => {
        if (window.confirm('정말로 탈퇴하시겠습니까?')) {
            try {
                await axios.delete(`https://api.go-reverse.site/api/v1/members/${auth.memberId}`);
                alert('탈퇴가 완료되었습니다.');
                // 쿠키 삭제
                document.cookie = 'Authorization=; Max-Age=0; path=/;';
                // 상태 관리 삭제
                setAuth({isLoggedIn: false, memberName: null, memberId: null});
                // 리다이렉트
                navigate("/");
            } catch (error) {
                console.error('탈퇴 실패:', error);
                alert('탈퇴에 실패했습니다. 다시 시도해 주세요.');
            }
        }
    };

    if (loading) return <p>인증 상태 확인 중...</p>;
    if (pageLoading) return <p>페이지 로딩 중...</p>;
    if (error) return <p>{error}</p>;
    if (!auth.isLoggedIn) return <p>로그인이 필요합니다.</p>;

    return (
        <div className={styles.container}>
            <Header/>
            <main className={styles.main}>
                <h1 className={styles.title}>MY PAGE</h1>
                {memberData && (
                    <div className={styles.myPage}>
                        <div className={styles.memberDetails}>
                            <div className={styles.welcome_message}>
                                <h2>{memberData.name}</h2>
                                <span>
                                    {memberData.role === 'ROLE_MEMBER' && ' 회원님 안녕하세요!'}
                                    {memberData.role === 'ROLE_LAWYER' && ' 변호사님 안녕하세요!'}
                                    {memberData.role === 'ROLE_ADMIN' && ' 관리자님 안녕하세요!'}
                                </span>
                            </div>

                            <p className={styles.email}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                                skysn9045@naver.com</p>
                            <p className={styles.phone}>
                                <FontAwesomeIcon icon={faPhone}/>
                                010-3215-6045</p>
                            {memberData.lawyerNumber &&
                                <p className={styles.lawyerNumber}>
                                    <FontAwesomeIcon icon={faIdCard}/>
                                    {memberData.lawyerNumber}</p>
                            }
                        </div>

                        <div className={styles.activities}>
                            <div className={styles.diagnosisResult}>
                                <h3> 진단 결과 </h3>
                                {memberData.possibility === 0 && '개인 회생 불가'}
                                {memberData.possibility === 1 && '개인 회생 가능'}
                                개인 회생 가능
                                <small> | 진단일 : 2024.09.01</small>
                            </div>

                            <div className={styles.activitySummary}>
                                <h3> 나의 활동 </h3>
                                <p> 작성한 글 : <big>5</big> 건 </p>
                                <p> 댓글 : <big>2</big> 건 </p>
                            </div>
                        </div>

                        <div className={styles.withdraw}>
                            <button onClick={deleteMember}
                            >리버스 탈퇴하기
                            </button>
                        </div>
                        {/*<p>possibility: {memberData.possibility}</p>*/}
                    </div>
                )}
            </main>
            <Footer/>
        </div>
    );

}

export default Mypage;