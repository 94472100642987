import React, { useState} from "react";
import {useNavigate} from "react-router-dom";
import NavBar from "../components/Navbar";
import FooterBlack from "../components/FooterBlack";

function Diagnosis() {
    const [children_exist, setChildren_exist] = useState(false);
    const [children_num, setChildren_num] = useState("0");
    const [partner_exist, setPartner_exist] = useState(false);
    const [partner_marriage, setPartner_marriage] = useState(null);
    const [partner_income, setPartner_income] = useState(false);
    const [parents_exist, setParents_exist] = useState(false);
    const [parents_num, setParents_num] = useState("0");
    const [sibling_exist, setSibling_exist] = useState(false);
    const [sibling_num, setSibling_num] = useState("0");
    const [disclaimer, setDisclaimer] = useState(false);
    const [salary, setSalary] = useState("0");
    const [property_value, setProperty_value] = useState("0");
    const [creditors_num, setCreditors_num] = useState("0");
    const [borrowed_money_exist, setBorrowed_money_exist] = useState(false);
    const [borrowed_money_amount, setBorrowed_money_amount] = useState("0");
    const [pawn_exist, setPawn_exist] = useState(false);
    const [pawn_amount, setPawn_amount] = useState("0");
    const [all_debts, setAll_debts] = useState("0");

    const navigate = useNavigate();

    const toDiagnosisResult = () => {
        navigate('/diagnosis-result')
    }

    const createDiagnosis = () => {
        fetch("https://api.go-reverse.site/api/v1/diagnosis-start", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                children_exist: children_exist,
                children_num: children_num,
                partner_exist: partner_exist,
                partner_marriage: partner_marriage,
                partner_income: partner_income,
                parents_exist: parents_exist,
                parents_num: parents_num,
                sibling_exist: sibling_exist,
                sibling_num: sibling_num,
                disclaimer: disclaimer,
                salary: salary,
                property_value: property_value,
                creditors_num: creditors_num,
                borrowed_money_exist: borrowed_money_exist,
                borrowed_money_amount: borrowed_money_amount,
                pawn_exist: pawn_exist,
                pawn_amount: pawn_amount,
                all_debts: all_debts,

            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("send", data);
            });
    };

    function handlePartnerMarriage() {
        switch (partner_marriage) {
            case true:
                return (
                    <div id="diagnosis-Q1-2" className="diagnosis-Q-div">
                        <div className="diagnosis-hr-div">
                            <hr/>
                        </div>
                        <div className="col-md-12 diagnosis-Q">
                            <p className="diagnosis-Q-font">1-2. 지금 그 분과 결혼 중인 상태신가요?</p>
                        </div>
                        <div className="col-md-6">
                            <span className="diagnosis-A-btn-span">
                                <button className="diagnosis-A-btn-select"
                                        onClick={() => {
                                            setPartner_marriage(true);
                                        }}
                                >결혼</button>
                            </span>
                            <span className="diagnosis-A-btn-span">
                                    <button className="diagnosis-A-btn"
                                            onClick={() => {
                                                setPartner_marriage(false);
                                                setPartner_income(true)
                                            }}
                                    >이혼</button>
                            </span>
                            <span>
                                <button className="diagnosis-A-btn"
                                        onClick={() => {
                                            setPartner_marriage(null);
                                            setPartner_income(true)
                                        }}
                                >미혼</button>
                            </span>
                        </div>
                    </div>
                );
            case false:
                return (
                    <div id="diagnosis-Q1-2" className="diagnosis-Q-div">
                        <div className="diagnosis-hr-div">
                            <hr/>
                        </div>
                        <div className="col-md-12 diagnosis-Q">
                            <p className="diagnosis-Q-font">1-2. 지금 그 분과 결혼 중인 상태신가요?</p>
                        </div>
                        <div className="col-md-6">
                            <span className="diagnosis-A-btn-span">
                                <button className="diagnosis-A-btn"
                                        onClick={() => {
                                            setPartner_marriage(true);
                                        }}
                                >결혼</button>
                            </span>
                            <span className="diagnosis-A-btn-span">
                                    <button className="diagnosis-A-btn-select"
                                            onClick={() => {
                                                setPartner_marriage(false);
                                                setPartner_income(true)
                                            }}
                                    >이혼</button>
                            </span>
                            <span>
                                <button className="diagnosis-A-btn"
                                        onClick={() => {
                                            setPartner_marriage(null);
                                            setPartner_income(true)
                                        }}
                                >미혼</button>
                            </span>
                        </div>
                    </div>
                );
            case null:
                return (
                    <div id="diagnosis-Q1-2" className="diagnosis-Q-div">
                        <div className="diagnosis-hr-div">
                            <hr/>
                        </div>
                        <div className="col-md-12 diagnosis-Q">
                            <p className="diagnosis-Q-font">1-2. 지금 그 분과 결혼 중인 상태신가요?</p>
                        </div>
                        <div className="col-md-6">
                            <span className="diagnosis-A-btn-span">
                                <button className="diagnosis-A-btn"
                                        onClick={() => {
                                            setPartner_marriage(true);
                                        }}
                                >결혼</button>
                            </span>
                            <span className="diagnosis-A-btn-span">
                                    <button className="diagnosis-A-btn"
                                            onClick={() => {
                                                setPartner_marriage(false);
                                            }}
                                    >이혼</button>
                            </span>
                            <span>
                                <button className="diagnosis-A-btn-select"
                                        onClick={() => {
                                            setPartner_marriage(null);
                                        }}
                                >미혼</button>
                            </span>
                        </div>
                    </div>
                );
        }
    }

    return (
    <div>
        <body className="main-layout inner_page">
        <NavBar
            activeValue = "diagnosis"
        />
        <div className="appointment">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="titlepage text_align_center">
                            <h2>진단하기</h2>
                        </div>
                        <div >
                            <hr/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div id="request" className="main_form text_align_center" >
                            <div className="row">
                                <div className="col-md-12 text_align_left">
                                    <div id="diagnosis-Q1" className="diagnosis-Q-div">
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">1. 양육하고 있는 자녀가 있나요?</p>
                                        </div>
                                        {children_exist ? (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setChildren_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setChildren_exist(false);
                                                            setPartner_exist(false)
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {children_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 명인가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setChildren_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                                    <span>
                                                            <text>명</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setChildren_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setChildren_exist(false);
                                                            setPartner_exist(false)
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {children_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 명인가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setChildren_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>명</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        )
                                        }
                                    </div>
                                    {children_exist && (
                                        <div id="diagnosis-Q1-1" className="diagnosis-Q-div">
                                            <div className="diagnosis-hr-div">
                                                <hr/>
                                            </div>
                                            <div className="col-md-12 diagnosis-Q">
                                                <p className="diagnosis-Q-font">1-1. 자녀를 같이 양육했던 상대(예: 배우자)가 계신가요?</p>
                                                <div className="col-md-6 diagnosis-Q-explain">
                                                    <p>✔ 이혼하셨어도 살아계시다면 있다고 해주세요.</p>
                                                </div>
                                            </div>
                                            {partner_exist ? (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setPartner_exist(true);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setPartner_exist(false);
                                                                    setPartner_marriage(null)
                                                                    setPartner_income(true)
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setPartner_exist(true);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setPartner_exist(false);
                                                                    setPartner_marriage(null)
                                                                    setPartner_income(true)
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    )}
                                    {children_exist && partner_exist && (
                                        handlePartnerMarriage()
                                    )}
                                    {partner_exist && partner_marriage && (
                                        <div id="diagnosis-Q1-3" className="diagnosis-Q-div">
                                            <div className="diagnosis-hr-div">
                                                <hr/>
                                            </div>
                                            <div className="col-md-12 diagnosis-Q">
                                                <p className="diagnosis-Q-font">1-3. 배우자가 장애 혹은 질병이 있어 수입이 없으신가요?</p>
                                            </div>
                                            {partner_income ? (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setPartner_income(false);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setPartner_income(true);
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setPartner_income(false);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setPartner_income(true);
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    )}
                                    <div id="diagnosis-Q2" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">2. 아래 조건에 모두 부합하는 부모님이 계신가요?</p>
                                            <div className="col-md-6 diagnosis-Q-explain">
                                                <p>✔ 친부모</p>
                                                <p>✔ 65세 이상</p>
                                                <p>✔ 매달 30만원 이상 생활비를 드림</p>
                                            </div>
                                        </div>
                                        {parents_exist ? (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setParents_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setParents_exist(false);
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {parents_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 분 계신가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setParents_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>명</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setParents_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setParents_exist(false);
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {parents_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 분 계신가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setParents_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>명</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                    )
                                    }
                                    <div id="diagnosis-Q3" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">3. 형제, 자매분이 계신가요?</p>
                                        </div>
                                        {sibling_exist ? (
                                            <div className="col-md-6">
                                        <span className="diagnosis-A-btn-span">
                                            <button className="diagnosis-A-btn-select"
                                                    onClick={() => {
                                                        setSibling_exist(true);
                                                    }}
                                            >네</button>
                                        </span>
                                                <span className="diagnosis-A-btn-span">
                                            <button className="diagnosis-A-btn"
                                                    onClick={() => {
                                                        setSibling_exist(false);
                                                    }}
                                            >아니요</button>
                                        </span>
                                                {sibling_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 명인가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setSibling_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>명</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-md-6">
                                                <span className="diagnosis-A-btn-span">
                                                    <button className="diagnosis-A-btn"
                                                            onClick={() => {
                                                                setSibling_exist(true);
                                                            }}
                                                    >네</button>
                                                </span>
                                                        <span className="diagnosis-A-btn-span">
                                                    <button className="diagnosis-A-btn-select"
                                                            onClick={() => {
                                                                setSibling_exist(false);
                                                            }}
                                                    >아니요</button>
                                                </span>
                                                        {sibling_exist &&
                                                        (
                                                            <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                                <span>
                                                                    <input
                                                                        className="diagnosis-moneyInput"
                                                                        placeholder="몇 명인가요?"
                                                                        type="text"
                                                                        onChange={
                                                                            (e) => setSibling_num(e.target.value)
                                                                        }
                                                                    />
                                                                </span>
                                                                <span>
                                                                    <text>명</text>
                                                                </span>
                                                            </div>
                                                        )
                                                        }
                                            </div>
                                        )
                                        }
                                    </div>
                                        <div id="diagnosis-Q4" className="diagnosis-Q-div">
                                            <div className="diagnosis-hr-div">
                                                <hr/>
                                            </div>
                                            <div className="col-md-12 diagnosis-Q">
                                                <p className="diagnosis-Q-font">4. 최근 5년 내 법원으로 부터 면책 결정을 받은 적이 있으세요?</p>
                                                <div className="col-md-10 diagnosis-Q-explain">
                                                    <p>✔ 법원의 면책 결정은 개인파산을 신청할 때나 개인회생을 완료한 경우에 받을 수 있어요.</p>
                                                </div>
                                            </div>
                                            {disclaimer ? (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setDisclaimer(true);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setDisclaimer(false);
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="col-md-6">
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn"
                                                                onClick={() => {
                                                                    setDisclaimer(true);
                                                                }}
                                                        >네</button>
                                                    </span>
                                                    <span className="diagnosis-A-btn-span">
                                                        <button className="diagnosis-A-btn-select"
                                                                onClick={() => {
                                                                    setDisclaimer(true);
                                                                }}
                                                        >아니요</button>
                                                    </span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div id="diagnosis-Q5" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">5. 월 평균 수입을 알려주세요.</p>
                                            <div className="col-md-10 diagnosis-Q-explain">
                                                <p>✔ 매달 실제로 통장에 입금되는 금액을 적어주세요.</p>
                                            </div>
                                        </div>
                                        <div className="diagnosis-moneyInput-div">
                                            <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                <span>
                                                    <input
                                                        className="diagnosis-moneyInput"
                                                        placeholder="월 평균 수입"
                                                        type="text"
                                                        onChange={
                                                            (e) => setSalary(e.target.value)
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    <text>원</text>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="diagnosis-Q6" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">6. 소유하고 계신 재산의 현재 시점 가치를 알려주세요.</p>
                                            <div className="col-md-9 diagnosis-Q-explain">
                                                <p>✔ 소유하고 계신 부동산에 담보가 설정되어 있거나 전세/월세 임차인 보증금처럼 돌려줘야 하는 돈이 있는 경우 그 금액만큼은 가치에서 빼주셔야 해요.</p>
                                            </div>
                                            <div className="col-md-6 diagnosis-Q-explainList">
                                                <div className="diagnosis-Q-explainList-title">
                                                    <text>재산은 아래와 같은 것들을 의미해요</text>
                                                </div>
                                                <div>
                                                    <text>● 예금 잔고</text>
                                                </div>
                                                <div>
                                                    <text>● 보험 해약 환급금</text>
                                                </div>
                                                <div>
                                                    <text>● 전세/월제 보증금</text>
                                                </div>
                                                <div>
                                                    <text>● 차량</text>
                                                </div>
                                                <div>
                                                    <text>● 부동산</text>
                                                </div>
                                                <div>
                                                    <text>● 예상 퇴직금</text>
                                                </div>
                                                <div>
                                                    <text>● 빌려주고 못 받은 돈</text>
                                                </div>
                                                <div>
                                                    <text>● 판매하고 못 받은 돈</text>
                                                </div>
                                                <div>
                                                    <text>● 사업용 설비나 재고</text>
                                                </div>
                                                <div>
                                                    <text>● 주식과 코인의 현재 시점의 가치</text>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="diagnosis-moneyInput-div">
                                            <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                <span>
                                                    <input
                                                        className="diagnosis-moneyInput"
                                                        placeholder="소유재산 가치"
                                                        type="text"
                                                        onChange={
                                                            (e) => setProperty_value(e.target.value)
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    <text>원</text>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="diagnosis-Q7" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">7. 채권자 수를 알려주세요.</p>
                                            <div className="col-md-9 diagnosis-Q-explain">
                                                <p>✔ 채권자란, 돈을 갚아야 할 대상을 의미해요.</p>
                                                <p> 대출을 받는 횟수와 관계없이 빌린 곳의 수를 써주시면 돼요.</p>
                                            </div>
                                            <div className="col-md-6 diagnosis-Q-explainList">
                                                <div className="diagnosis-Q-explainList-title">
                                                    <text>예를 들어</text>
                                                </div>
                                                <div>
                                                    <text>● XX은행 10건</text>
                                                </div>
                                                <div>
                                                    <text>● YY카드 2건</text>
                                                </div>
                                                <div>
                                                    <text>● ZZ텔레콤 1건</text>
                                                </div>
                                                <div>
                                                    <text>● 둘째삼촌 1건</text>
                                                </div>
                                                <div className="diagnosis-Q-explainList-footer">
                                                    <text>위의 경우에는 채권자가 4명이 돼요.</text>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="diagnosis-moneyInput-div">
                                            <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="몇 명인가요?"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setCreditors_num(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                <span>
                                                    <text>명</text>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="diagnosis-Q8" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">8. 최근 1년동안 빌린 돈이 있으신가요?</p>
                                        </div>
                                        {borrowed_money_exist ? (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setBorrowed_money_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setBorrowed_money_exist(false);
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {borrowed_money_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="빌린 금액"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setBorrowed_money_amount(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>원</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setBorrowed_money_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setBorrowed_money_exist(false);
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {borrowed_money_exist &&
                                                (
                                                    <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="빌린 금액"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setBorrowed_money_amount(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            <text>원</text>
                                                        </span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        )
                                        }
                                    </div>
                                    <div id="diagnosis-Q9" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">9. 담보부 채무가 있으신가요?</p>
                                            <div className="col-md-6 diagnosis-Q-explainList">
                                                <div className="diagnosis-Q-explainList-title">
                                                    <text>담보부 채무가 뭐죠?</text>
                                                </div>
                                                <div>
                                                    <text>● 담보부 채무는 돈을 빌릴 때 채권자(빌려주는 사람)에게 부동산이나 자동차 등의</text>
                                                </div>
                                                <div>
                                                    <text>재산을 담보로 제공하는 것을 말해요.</text>
                                                </div>

                                            </div>
                                        </div>
                                        {pawn_exist ? (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setPawn_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setPawn_exist(false);
                                                            setPawn_amount("0")
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {pawn_exist &&
                                                (
                                                    <div id="diagnosis-Q9-1" className="diagnosis-Q-div">
                                                        <div className="diagnosis-hr-div">
                                                            <hr/>
                                                        </div>
                                                        <div className="col-md-12 diagnosis-Q">
                                                            <p className="diagnosis-Q-font">9-1. 담보부 채무 원금 합계를 입력해 주세요.</p>
                                                        </div>
                                                        <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="담보부 채무"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setPawn_amount(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                            <span>
                                                            <text>원</text>
                                                        </span>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        ) : (
                                            <div className="col-md-6">
                                            <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn"
                                                        onClick={() => {
                                                            setPawn_exist(true);
                                                        }}
                                                >네</button>
                                            </span>
                                                <span className="diagnosis-A-btn-span">
                                                <button className="diagnosis-A-btn-select"
                                                        onClick={() => {
                                                            setPawn_exist(false);
                                                            setPawn_amount("0")
                                                        }}
                                                >아니요</button>
                                            </span>
                                                {pawn_exist &&
                                                (
                                                    <div id="diagnosis-Q9-1" className="diagnosis-Q-div">
                                                        <div className="diagnosis-hr-div">
                                                            <hr/>
                                                        </div>
                                                        <div className="col-md-12 diagnosis-Q">
                                                            <p className="diagnosis-Q-font">9-1. 담보부 채무 원금 합계를 입력해 주세요.</p>
                                                        </div>
                                                        <div className="col-md-6 diagnosis-moneyInput-divInner">
                                                        <span>
                                                            <input
                                                                className="diagnosis-moneyInput"
                                                                placeholder="담보부 채무"
                                                                type="text"
                                                                onChange={
                                                                    (e) => setPawn_amount(e.target.value)
                                                                }
                                                            />
                                                        </span>
                                                            <span>
                                                            <text>원</text>
                                                        </span>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        )
                                        }
                                    </div>
                                    <div id="diagnosis-Q10" className="diagnosis-Q-div">
                                        <div className="diagnosis-hr-div">
                                            <hr/>
                                        </div>
                                        <div className="col-md-12 diagnosis-Q">
                                            <p className="diagnosis-Q-font">10. 모든 채무의 원금 합계를 알려주세요.</p>
                                            {pawn_exist && (
                                                <div className="col-md-9 diagnosis-Q-explain">
                                                    <p>✔ 방금 전 단계에서 입력하신</p>
                                                    <p> 담보부 채무 <text className="highlightText">{pawn_amount} 원</text> 을 포함시켜 입력해 주세요!</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="diagnosis-moneyInput-div">
                                            <div className="col-md-6 diagnosis-moneyInput-divInner">
                                            <span>
                                                <input
                                                    className="diagnosis-moneyInput"
                                                    placeholder="모든 채무"
                                                    type="text"
                                                    onChange={
                                                        (e) => setAll_debts(e.target.value)
                                                    }
                                                />
                                            </span>
                                                <span>
                                                <text>원</text>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="diagnosis-hr-div">
                                        <hr/>
                                    </div>
                                    <div className="diagnosis-start-div">
                                        <button
                                            className="diagnosis-start-btn"
                                            onClick={() => {
                                                createDiagnosis();
                                                toDiagnosisResult();
                                            }}
                                        >결과 보기</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterBlack/>
        </body>
    </div>
    );
}

export default Diagnosis;