import React, { useState } from "react";
import styles from './Signup.module.css';

function SignupForm({ onSubmit, error }) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [lawyerNumber, setLawyerNumber] = useState("");
    const [showLawyerInput, setShowLawyerInput] = useState(false);

    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/(\d{3})(\d{4})(\d{4})/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return value;
    };

    const handlePhoneChange = (event) => {
        const { value } = event.target;
        const filteredValue = value.replace(/[^0-9-]/g, ''); // 숫자와 '-'만 허용
        const formattedPhone = formatPhoneNumber(filteredValue);
        setPhone(formattedPhone);
    };

    const handleLawyerChange = (event) => {
        const inputValue = event.target.value;
        const filteredValue = inputValue.replace(/[^0-9-]/g, ''); // 숫자와 '-'만 허용
        setLawyerNumber(filteredValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, phone, lawyerNumber });
    };

    const toggleLawyerInput = () => {
        setShowLawyerInput(!showLawyerInput); // 체크박스 클릭 시 변호사 번호 입력 필드 보이기/숨기기
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div>
                <label className={styles.label}>
                    이름
                    <input
                        className={styles.input}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    전화번호
                    <input
                        className={styles.input}
                        type="text"
                        value={phone}
                        onChange={handlePhoneChange}
                        placeholder="전화번호를 입력하세요"
                        required
                    />
                </label>
            </div>
            <div>
                <label>
                    변호사 회원 가입
                    <input
                        type="checkbox"
                        checked={showLawyerInput}
                        onChange={toggleLawyerInput} // 체크박스 토글 핸들러
                    />
                </label>
            </div>
            {showLawyerInput && ( // 변호사 번호 입력 필드 조건부 렌더링
                <div>
                    <label className={styles.label}>
                        <input
                            className={styles.input}
                            type="text"
                            value={lawyerNumber}
                            onChange={handleLawyerChange}
                            placeholder="자격 번호를 입력하시면 검증 후 법조인 회원으로 변경됩니다"
                        />
                    </label>
                </div>
            )}
            {error && <p className={styles.error}>{error}</p>}
            <button className={styles.button} type="submit">회원가입</button>
        </form>
    );
}

export default SignupForm;
