import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProfileToggle.module.css'; // 스타일 파일 불러오기

const ProfileToggle = ({ auth, profileImage, handleLogout }) => {
    const [isOpen, setIsOpen] = useState(false); // 상태 관리
    const navigate = useNavigate(); // navigate 훅 사용


    const toggleDropdown = () => {
        setIsOpen(prev => !prev); // 토글 함수
    };

    const goToMypage = () => {
        navigate('/mypage'); // "/mypage"로 이동
        setIsOpen(false); // 드롭다운 닫기
    };

    return (
        <div style={{ position: 'relative' }}>
            <img
                src={profileImage}
                alt="Profile Image"
                className={styles.profile}
                onClick={toggleDropdown} // 클릭 시 토글
            />
            {isOpen && (
                <div className={styles.dropdown}> {/* 드롭다운 스타일 적용 */}
                    <button className={styles.authLink}
                            onClick={goToMypage}>
                        {auth.memberName}
                    </button>
                    <div className={styles.separator}></div>
                    <button onClick={handleLogout}
                            className={styles.authLink}>
                        로그아웃
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProfileToggle;
