import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {useUserContext} from '../../UserContext';
import styles from './EditPost.module.css';

function EditPost() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {postId} = useParams();
  const navigate = useNavigate();
  const {user} = useUserContext();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://api.go-reverse.site/api/v1/communities/${postId}`);
        if (user.memberId !== response.data.memberId) {
          setError('게시글을 수정할 권한이 없습니다.');
          setLoading(false);
          return;
        }
        setTitle(response.data.title);
        setContent(response.data.content);
        setLoading(false);
      } catch (error) {
        console.error('게시글 불러오기 실패', error);
        setError('게시글을 불러오는데 실패했습니다.');
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId, user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.go-reverse.site/api/v1/communities/${postId}`, {title, content});
      navigate(`/community/${postId}`);
    } catch (error) {
      console.error('게시글 수정 실패', error);
      setError('게시글 수정에 실패했습니다.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
      <div className={styles.editPost}>
        <h2>게시글 수정</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="title">제목</label>
            <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
          </div>
          <div>
            <label htmlFor="content">내용</label>
            <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
            />
          </div>
          <div className={styles.buttons}>
            <button type="submit">수정 완료</button>
            <button type="button"
                    onClick={() => navigate(`/community/${postId}`)}>취소
            </button>
          </div>
        </form>
      </div>
  );
}

export default EditPost;