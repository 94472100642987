import React, {useState, useEffect, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import styles from "./Header.module.css";
import ProfileToggle from "./ProfileToggle";
import profileImage from "../assets/icon-user-profile.png";
import LoginModal from "./LoginModal";
import PreparationModal from "./PreparationModal";

function NavBar({activeValue}) {
    const [diagnosisActive, setDiagnosisActive] = useState("");
    const [infoActive, setInfoActive] = useState("");
    const [firmActive, setFirmActive] = useState("");
    const [communityActive, setCommunityActive] = useState("");
    const [aiActive, setAiActive] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPreparationOpen, setIsPreparationOpen] = useState(false);

    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);

    const handleLogout = () => {
        // 쿠키 삭제
        document.cookie = 'Authorization=; Max-Age=0; path=/;';
        // 상태 관리 삭제
        setAuth({ isLoggedIn: false, memberName: null, memberId: null });
        // 리다이렉트
        navigate("/");
    };


    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openPreparationModal = () => {
        setIsPreparationOpen(true);
    };

    const closePreparationModal = () => {
        setIsPreparationOpen(false);
        navigate(-1); // 이전 페이지로
    };

    useEffect(() => {
        switch (activeValue) {
            case "diagnosis":
                setDiagnosisActive("active");
                break;
            case "info":
                setInfoActive("active");
                break;
            case "firm":
                setFirmActive("active");
                break;
            case "community":
                setCommunityActive("active");
                break;
            case "ai-chat":
                setAiActive("active");
                openPreparationModal()
                break;
        }
    }, [activeValue]);

    return (
        <div className="full_bg">
            <header className="header-area">
                <div className="container">
                    <div className="row d_flex">
                        <div className=" col-md-3 col-sm-3">
                            <div className="logo">
                                <Link to="/reverse">Reverse</Link>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9">
                            <div className="navbar-area">
                                <nav className="site-navbar">
                                    <ul>
                                        <li><Link to="/pre-diagnosis" className={diagnosisActive}>진단</Link></li>
                                        <li><Link to="/info" className={infoActive}>유용한 정보</Link></li>
                                        <li><Link to="/firm" className={firmActive}>법무법인 찾기</Link></li>
                                        <li><Link to="/community" className={communityActive}>커뮤니티</Link></li>
                                        <li><Link to="/ai-chat" className={aiActive}>준비가이드</Link></li>
                                    </ul>
                                    <button className="nav-toggler">
                                        <span></span>
                                    </button>
                                    <div className={styles.authContainer}>
                                        {auth.isLoggedIn && auth.memberName ? (
                                            <ProfileToggle auth={auth}
                                                           handleLogout={handleLogout}
                                                           profileImage={profileImage}
                                            />
                                        ) : (
                                            <button onClick={toggleModal} className={styles.authLink}>로그인</button>
                                        )}
                                    </div>
                                    <LoginModal isOpen={isModalOpen}
                                    onClose={toggleModal} 
                                    />
                                    <PreparationModal isOpen={isPreparationOpen} onClose={closePreparationModal} />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}
export default NavBar;